.close_drawer_button {
  position: absolute;
  right: -30px;
  border: none;
  background: white;
  cursor: pointer;
  border-radius: 0px 12px 12px 0px;
}
.close_drawer_button:focus {
  outline: none;
}
.app-sidebar {
}
#menu_backdrop {
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background: rgba(0, 0, 0, 0.11);
  position: absolute;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 768px) {
  .app-sidebar {
    left: 0px !important;
  }
  .close_drawer_button {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .app-sidebar {
    margin-top: 59px;
  }
}
