.icon {
  margin-top: 32px;
  margin-left: 90%;
  font-size: 15px;
}
@media (max-width: 480px) {
  .icon {
    margin-left: 85%;
  }
}
@media (max-width: 991px) {
  .icon {
    margin-left: 93%;
  }
}
.form-group {
  position: relative;
}
.form-container {
  position: absolute;
}
.invalid-feedback.left {
  text-align: left;
}
