.table_container {
  border-radius: 16px;
  width: auto;
  overflow-x: auto;
  padding: 0px;
  border-collapse: collapse;

  background: white;
}
.table_build thead td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.table_build tbody td {
  border-collapse: collapse;
  border-right: 1px solid rgba(0, 0, 0, 0.192);
  border-bottom: 1px solid rgba(0, 0, 0, 0.192);
}
.table_build tbody tr:last-of-type td {
  border-bottom: none;
}

.table_build .branch_title {
  padding: 0px 5px;
  white-space: nowrap;
  font-weight: 500;
  text-align: center;
  border-left: none;
  color: #7c7bad;
  border-right: 1px solid rgba(0, 0, 0, 0.192);
}
.table_build .branch_title:last-of-type {
  border-bottom: 0px;
}
.table_build td {
  padding: 25px;
  position: relative;
}
.nightmode {
  background: #f2dede;
}
.daymode {
  background: #dff0d8;
}
.far.fa-moon {
  color: blue;
}
.far.fa-sun {
  color: orange;
}
.far {
  border: none;
  position: absolute;
  top: 5px;
  left: 5px;
}
.table_container:hover::-webkit-scrollbar-thumb {
  overflow: hidden;
  background: #6b6aaa;
}
.table_container::-webkit-scrollbar:horizontal {
  width: 20px;
  height: 7px;
}
.table_container::-webkit-scrollbar-track {
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}

/* Handle */
.table_container::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  width: 10px;
  background: #7c7bad;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* Handle on hover */
.table_container::-webkit-scrollbar-thumb:hover {
  transition: all 0.3s ease-in-out;
  background: #6b6aaa;
}
.fa.fa-thumbs-up,
.fa.fa-thumbs-down {
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.fa.fa-thumbs-up {
  color: green;
}
.fa.fa-thumbs-down {
  color: red;
}
.table_container_wrapper {
  overflow-x: hidden;
  border-radius: 16px;
  padding: 0px;
  height: auto;
  margin: 4vh 10px;
  border: 1px solid rgba(0, 0, 0, 0.062);
  margin: 0px;
  -webkit-box-shadow: 3px 3px 15px 2px rgba(124, 123, 173, 0.26);
  -moz-box-shadow: 3px 3px 15px 2px rgba(124, 123, 173, 0.26);
  box-shadow: 3px 3px 15px 2px rgba(124, 123, 173, 0.26);
}
