.sidebar_ul_container {
}
.sidebar_ul_container > ul {
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}
.sidebar_ul_container > ul li {
  padding: 15px;
}
.sidebar_ul_container > ul li.active {
  background: #7c7bad;
  transition: all 0.3s ease-in-out;
  color: white !important;
}
.sidebar_ul_container > ul li.active a {
  transition: all 0.3s ease-in-out;
  color: white;
}
.close_icon {
  position: absolute;
  top: 10px;
  right: 25px;
}
