body::-webkit-scrollbar-track {
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 10px;
  background: #7c7bad;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
body::-webkit-scrollbar-corner {
  background: transparent;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  transition: all 0.3s ease-in-out;
  background: #6b6aaa;
}

.form-control.disabled{
  background-color: #f8f9fa;
    opacity: 1;
}