.footer {
  position: fixed;
  /*z-index: 1000;*/
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0px;
  height: 35px;
}
div.copyright {
  align-content: center;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}

@media (min-width: 768px) {
  div.copyright {
    margin-left: 260px;
  }
}
