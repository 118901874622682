._1GZw8ddEV0LRTgWTC7qpJp.LPKCXpuSnN4iRDju8uk4n a {
  margin: 0;
}
._1GZw8ddEV0LRTgWTC7qpJp.LPKCXpuSnN4iRDju8uk4n {
  font-size: 12px;
  right: 10px;
}
.custombtnUI {
  position: absolute;
  top: 2px;
}
.button_UI_container {
  width: 55%;
  text-align: right;
  margin-left: auto;
  position: absolute;
  margin-right: auto;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.custombtnUI {
  color: #7c7bad;
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: none;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.custombtnUI:focus {
  outline: none;
}
#log::-webkit-scrollbar-track {
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}

/* Handle */
#log::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 10px;
  background: #7c7bad;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
#log::-webkit-scrollbar-corner {
  background: transparent;
}

/* Handle on hover */
#log::-webkit-scrollbar-thumb:hover {
  transition: all 0.3s ease-in-out;
  background: #6b6aaa;
}
@media (max-width: 900px) {
  .custombtnUI {
    position: relative;
    display: block;
  }
  .button_UI_container {
    width: 100%;
    text-align: center;
    margin-left: auto;
    position: sticky;
    margin-right: auto;
  }
}
