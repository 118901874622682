.github-command-row {
  font-size: 16px;
  background: rgba(0, 0, 0, 0.651);
  border-radius: 8px;
  height: 40px;
  color: white;
  vertical-align: middle;
}

.github-command-row span {
  margin-bottom: -3px;
  padding: 9px;
}
.github-command-row input {
  background: transparent;
  border: none;
  color: white;
  text-decoration: underline red;
  font-weight: 600;
}
.github-command-row button:focus {
  outline: none;
}
.info_box {
  font-size: 12px;
  color: rgba(128, 128, 128, 0.733);
}
.nav.panel-tabs {
  margin: 0px 3px 0px -10px;
}
.nav.panel-tabs a {
  border-radius: 8px 8px 0px 0px;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  margin-right: 3px;
}
.tab-menu-heading {
  height: auto;
  padding: 11px;
  border: none;
}
.nav.panel-tabs a:hover {
  transition: all 0.3s ease-in-out;
  background: #7c7badc4;
  color: white;
}
.nav.panel-tabs .active {
  transition: all 0.3s ease-in-out;
  color: white;
  background: #7c7bad;
}
.panel-body.tabs-menu-bodyGIT {
  padding: 0px;
}
