.tab-pane#logtab {
  height: 800px;
}
.panel-body.tabs-menu-body {
  padding: 0;
}
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 41%;
  width:115%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;
  
  -webkit-transform:rotate(43deg);
  -moz-transform:rotate(43deg);
  -ms-transform:rotate(43deg);
  -o-transform:rotate(43deg);
  transform:rotate(43deg);
}


*:disabled{
  cursor: auto;
}